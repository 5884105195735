@use 'vars' as *;

/*============ Contact ============*/
.contact-details.has-map,
.map-wrap {
    display: inline-block;
    vertical-align: bottom;
    width: 48%;
    box-sizing: border-box;
}
.contact-details.has-map {
    padding-right: 10px;
}

.contact-details p {
    margin: 5px 0;
}
.opening-hours {
    display: block;
}
.contact-wrapper form {
    padding-top: 20px;
}
.contact-wrapper form p {
    margin: 10px 0;
}

.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 280px;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

.contact-cta{
    padding:6rem 2rem;
    @media only screen and (max-width: 576px) {
        padding:2rem
    }
    .title{
        text-align: center;
        margin-top:0;
        margin-bottom: 1rem;
        font-size: clamp(25px, 3vw, 38px);
        font-family: $tertiary-font; 
    }
    .cta-btns{
        display:flex;
        justify-content: center;
        .cta-btn{
            text-align: center;
            color:#fff;
            border:1px solid #fff;
            background-color: transparent;
            margin:10px;
            text-decoration: none;
            font-family: $primary-font;
            text-transform: uppercase;
            padding:.5rem 1rem;
            width: 200px; 
            font-weight: 200;
            font-size: 16px;
            letter-spacing: 2px;
            transition: all .3s ease-out;
            cursor: pointer;
            
            &:hover{
                color:#fff;
                background-color: #0C1116;
                border-color:#0C1116 ;
            }
            @media only screen and (max-width: 576px) {
                width: 100%; 
            }
        }
        @media only screen and (max-width: 760px) {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
    }

   
}
.contact-details{
    color:#fff;
    text-align: center;
    
    padding:6rem 2rem;
    padding-bottom: 4rem;
    .title{
   
        margin-top: 0;
        margin-bottom: 1rem;
        font-family: $tertiary-font; 
        font-size: clamp(25px, 3vw, 38px);
      
    }
   p{
        font-weight: 100;
        font-size: 18px;
        letter-spacing: 1px;
   }
   .office-social-links{
        margin-top: 6rem;
        display: flex;
        text-transform: uppercase;
        justify-content: center;
        @media only screen and (max-width: 576px) {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
       a{
           margin:0 1rem;
           font-weight: 100;
           font-size: 18px;
           letter-spacing: 1px;
       }
   }
}
