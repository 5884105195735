.t-news{
    .page-graphic{
        
        img{
            width: 100%;
            margin:0;
            margin-top: clamp(5px, 2vw, 2rem);
            margin-bottom: clamp(5px, 2vw, 2rem);
        }
    }
    .page-title{
        margin-top:3rem;
        font-size: clamp(25px, 3vw, 38px);
    }
    .story-list{
        display:grid;
        grid-gap:6rem 1rem;
        grid-template-columns: repeat(2, 1fr);
        padding:3rem 20px;
        
        .card{
            a{
                text-decoration: none;
            }
            picture{
                display: block;
                position: relative;
                transition: background-color .3s ease-in-out;
                img{
                    width: 100%;
                    height: 18vw!important;
                    object-fit: cover;
                    mix-blend-mode: multiply;
                }
            }
           
            h2{
                font-weight: 100;
                text-transform: uppercase;
                letter-spacing: 2px;
                color:#0C1116;
                margin-top: clamp(.75rem, 2.5vw, 2rem); 
                margin-bottom: 1rem;
                font-size: clamp(16px, 2.5vw, 25px); 
            }
            &:hover{
                picture{
                    background-color: #BAB6B6;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    &::after{
                        content:"READ MORE";
                        padding:.5rem 6vw;
                        border:1px solid #fff;
                        margin:auto;
                        position: absolute;
                        z-index: 10;
                        color:white;
                        font-weight: 100;
                        letter-spacing: 2px;
                    }
                }
                h2{
                    text-decoration: underline;
                }
            }
            .tags{
                display:flex;
                flex-wrap: wrap;
                justify-content: flex-start;
               
                a{
                    
                    color:#fff;
                    padding:5px;
                    margin-right:5px;
                    margin-bottom:5px;
                    transition: filter .3s ease-in-out;
                    &:hover{
                        filter:brightness(1.2);
                    }
                }
               
                
            }
        }
        @media only screen and (max-width: 760px) {
            grid-template-columns:1fr;
            .card{
                picture img{
                    height: 35vw!important;
                }
            }
        }
    }   
    .back-btn{
        padding:.5rem 6rem;
        border:1px solid #0C1116;
        font-weight: 100;
        letter-spacing: 2px;
        text-decoration: none;
        color:#0C1116;
        margin-bottom: 2rem;
        display: inline-block;
        transition: background-color .3s ease-in-out,color .3s ease-in-out;
        &:hover{
            background: #0C1116;
            color:#fff
        }
    } 
}