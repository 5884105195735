@use 'vars' as *;

.t-office{
    header + .splash{
        min-height: 100vh;
    }
    .content-section{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        padding:8rem 20px;
        grid-gap: 4rem;
        .content-logo{
            display:flex;
            justify-content: center;
            img{
                width: 100%;
            }
        }
        @media only screen and (max-width: 576px) {
            .content-logo{
                img{
                    width: 50vw; 
                }
            }
            
        }
    }
    .divider-image{
        width: 100%;
        display: flex;
        justify-content: stretch;
        picture, img {
            width: 100%;
        }
    }
    .office-banner-section{
        padding:min(3vw,8rem) 20px;
     
        a{
            text-decoration: none;
        }
        .contentwrap{
            .title{
                color:#fff;
                text-align: center;
                font-family: $tertiary-font;
                margin-bottom: 2rem;
                letter-spacing: 2px;
            }
            .card{
              
                width: 100%;
                padding: 0 10px;
                display: block;
               
                img{
                    width: 100%;
                    aspect-ratio: 7/10;
                    object-fit: cover;
                }
                .related-page-title{
                    color:#0C1116;
                    text-align: center;
                    letter-spacing: 2px;
                }
                picture{
                    display: block;
                    position: relative;
                }
                &:hover{
                    picture{
                        
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        &::before{
                            content:"";
                            position: absolute;
                            inset:0;
                            z-index: 10;
                            mix-blend-mode: multiply;
                            background-color: #BAB6B6;
                        }
                        &::after{
                            content:"READ MORE";
                            max-width: 200px;
                            width: 20vw;
                            padding:.5rem 1rem;
                            border:1px solid #fff;
                            margin:auto;
                            position: absolute;
                            z-index: 10;
                            color:white;
                            font-weight: 100;
                            letter-spacing: 2px;
                            text-align: center;
                            @media only screen and (max-width: 576px) {
                                border:none;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .splide__track{
                margin:0 4rem;
                @media only screen and (max-width: 400px) {
                    margin:0;
                }
            }
            .splide__arrow{
                background: transparent;
            }
        }
       
    }
    .testimonial-section{
        display:flex;
        box-sizing: border-box;
        width: 100%;
        @media only screen and (max-width: 760px) {
            flex-direction: column-reverse;
        }
        .testimonial-content, .testimonial-icon{
            flex-basis: 0;
            flex: 1;
            min-width:50%;
        }
        .testimonial-icon img{
            width: 100%;
            height: 100%!important;
            object-fit: cover;
        }
        .testimonial-content{
            padding:2rem 1rem;
            display:flex;
            // width:100%;
            align-items: center;
            justify-content: center;
            // flex-basis: min-content;
          
            section{
                width: 100%;
            }
            .card{
                text-align:center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .summary{
                    font-family: $tertiary-font;
                    font-size: clamp(25px, 3vw, 38px);
                    margin-bottom:1rem;
                }
                .title{
                    font-size: clamp(16px, 2vw, 18px);
                    margin-top:0;
                }
                .text{
                    color:#fff;
                    font-size: clamp(16px, 2vw, 18px);
                    font-style: italic;
                    max-width: 435px;
                    margin:2rem auto;
                    letter-spacing: 2px;
                }
            }
            .splide__pagination__page{
                background-color: #0C1116;
            }
            .splide__list{
                width: 100%;
            }
        }
    }
    .footer-image{
        max-height: 522px;
        height:35vw;
        min-height: 200px;
        img{
            width:100%;
            height: 100%!important;
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 760px) {
        .content-section{
            display: flex;
            flex-direction: column-reverse;
        }
    }
}