/*============ splash ============*/
.t-home{
    .office-list{
        display:flex;
        flex-direction: column;
        align-items: stretch;
        position:relative;
        
        img{
           
        }

        .office-wrapper{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
            .bg{
                position: absolute;
                inset:0;
                z-index: 0;
                background-color: #D2D2D2;
                img{
                    object-fit: cover;
                    height: 100%!important;
                    width: 100%;
                    mix-blend-mode: multiply;
                }
            }
            .logo > img, a{
                z-index: 1;
                position: relative;
            }
            a{
                text-decoration: none;
                color:white;
                padding:1rem 2rem;
                border: 1px solid transparent;
                transition: border 0.5s ease;
                &:hover{
                  
                    border: 1px solid white;
                }
            }
        }
    }
}