@use 'vars' as *;

/*============ sub page============*/
.page-content {
    padding:4rem 1rem;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom:4rem;
    .page-title{
        margin-bottom: 0;
        padding-bottom: 0;
        font-family: $tertiary-font;
        
    }
}
.page-graphic{
    width: 100%;
    img{
        width: 100%;
    }
}
