/*============ header ============*/
header.top {
    position: absolute;
    font-weight: 300;
    font-size: 18px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.header-bar-top {
    color:#ffffff;
    display: flex;

    width: 100%;
}

header.top ul {
    text-transform: uppercase;
}
header.top li {
    vertical-align:top;
  
}

header + .splash{
    height: 30vw;
    min-height: 200px;
    position: relative;
    .bg{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%!important;
            object-fit: cover;
            mix-blend-mode: multiply;
            object-position: center bottom;
        }
    } 
    background-color: #D2D2D2;
    .heading{
        position: absolute;
        inset: 0;
        z-index: 2;
        padding-top: 1rem;
        picture{
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            img{
                width: 30vw;
                height: 100%!important;
                max-width: 500px;
                min-width: 170px;
                object-fit: contain;
            }
        }
        
    }
    .summary{
        position: absolute;
        left: 0;
        right: 0;
        bottom: min(200px, 20vh);
        z-index: 3;
        text-align: center;
        color:#fff;
        letter-spacing: 2px;
        font-weight: 100;
    }
}
.header-menu > ul {
    text-align:right;
}
.header-menu li {
    margin:20px;
}

.main-menu {
    display: flex;
    flex-grow: 1;
    margin: 0 1rem;
    justify-content: space-between;
    align-items: center;
}
.main-menu > ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.main-menu ul {
    text-align:justify;
    line-height:0;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    padding:0 5px;
    line-height:1;
    // &.active{
    //     color:black;
    // }
}
.main-menu li.depth-0 {
    vertical-align:middle;
    position: relative;
    padding: 1rem;
    letter-spacing: 2px;
    transition: background .3s ease-in-out;
    background-color: transparent;
    li{
        width: 100%;
        &:hover{
            background-color: rgba(255,255,255,.2);
        }
    }
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right: 0;
    background:inherit;
    color:#E5E2E1;
    z-index:10000;
    text-align: left;
    overflow: hidden;
    transition: max-height .3s ease-in-out;
    display: block;
    max-height:0px;
}
.main-menu li:hover > ul {
    max-height:500px;
}

.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.main-menu .depth-1 a {
    padding:.75rem;
}