@use 'vars' as *;


/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 10px;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    font-family: $primary-font;
    border: 1px solid #0C1116;
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    top: 0;
    text-align: center;
    text-transform: uppercase;
    color: inherit;
    line-height: 2;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
    width: 100%;
    letter-spacing: 2px;
    font-weight: 200;
}
.js-float-wrap {
    position:relative;
    margin:5px;
    flex-grow: 1;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    top: 2px;
    padding-left: 0;
    line-height: 1;
    font-size: 0.7em;
    width:0;
}
form .button {
    font-family: $primary-font;
    text-transform: uppercase;
    width: 220px;
    cursor: pointer;
    background-color: transparent;
    color:#0C1116;
    padding:.5rem 1rem;
    margin-bottom: .5rem;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 100;
    &:hover{
        background-color: #0C1116;
        color:#fff;
    }
}
.subsribe-form, .contact-form{
    text-align: center;
    font-size: 16px;
    
    label, button{
        text-transform: uppercase; 
    }
    .input-group{
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 760px) {
            flex-direction: column;
            justify-content: stretch;
        }
    }
    input, textarea{
        background-color: transparent;
    }
    button{
        @media only screen and (max-width: 760px) {
            width: 100%;
            box-sizing:border-box;
            margin: 0!important;
        }
    }
  
    
}
.subsribe-form{
    background-color:#EEEDEC;
    padding:3rem 2rem;
    label{
        font-family: $primary-font;
        line-height: 40px;
    }
    .title{
        margin-top: 0;
        font-family: $tertiary-font;
        font-size: clamp(18px, 3.5vw, 38px);
        font-weight: 900;
    }
    button{
        margin: 5px;
        flex-grow: 1;
       
    }
    .subcribe-name-input{
        flex-grow: 1;
    }
    .subcribe-email-input{
        flex-grow: 3;
    }
    .js-float-wrap {
        margin:5px;
        @media only screen and (max-width: 760px) {
            margin: 0;
            margin-bottom: 15px;
        }
    }
}

.contact-form{

    background-color: #E5E2E1;
    overflow-y: hidden;
    max-height: 0;
    transition: max-height .5s ease-in-out;
    form{
        padding:4rem 2rem;
    }

    &.active{
        max-height: 1000px;
    }
    .js-float-wrap{
        margin:5px;
        @media only screen and (max-width: 760px) {
            margin: 5px 0;
        }
    }
}