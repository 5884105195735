/*============ footer ============*/
footer .footer-bottom {
    background-color:#000000;
    /*background:#000000 url('/app/img/footer-bg.jpg') center no-repeat;*/
    background-size:cover;
    color:#fff;
}
.footer-banner{
    background-color: #E5E2E1;
    letter-spacing: 2px;
    color:#0C1116;
    text-align: center;
    padding:2rem;
    font-size: 19px;
    line-height: 22px;
    p{
        margin:0;
    }
}
footer{
    background-color:#E5E2E1;
}
footer .footer-offices{
    display:flex;
    justify-content: flex-start;
    padding:6rem 0;
    .active{
        img{
            filter: contrast(1)grayscale(0)brightness(1);
        }
    }
    img{
        width: auto;
        height: 15vw!important;
        object-fit: contain;
        filter:contrast(0)grayscale(1)brightness(0.5);
        transition: filter .2s ease-in-out;
        &:hover{
            filter: contrast(1)grayscale(0)brightness(1);
        }
    }
    .footer-main-office{
        display: flex;
        justify-self: center;
        flex-direction: column;
        position: relative;
        margin-bottom: 2rem;
        a {
            width: 25vw;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            picture{
                width: 55%;
                margin:auto;
            }
        }
        &::after{
            content:"";
            position: absolute;
            right: 0;
            top:5%;
            bottom:5%;
            width: 1px;
            background-color: #707070;
            @media only screen and (max-width: 768px) {
                bottom:initial;
                top:100%;
                right:25%;
                left:25%;
                height: 2px;
                width: initial;
            }
        }
        .abn{
            text-align: center;
            letter-spacing: 2px;
            font-size: 12px;
            margin:0;
            margin-top:2rem;
            color:#E5E2E1;
            @media only screen and (max-width: 768px) {
                margin-bottom: 2rem;
            }
        }
      
    }
    .footer-other-office{
        flex-grow: 1;
        display: flex;
        justify-content: space-evenly;
        padding:0 4rem;
 

        a{
            width: 100%;
            display: flex;
            justify-content: center;
            img{
                height: 15vw!important;
                width: auto;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        img{
            padding:1.5rem 0;
        }
        .footer-main-office{
            width: 100%;
            text-align: center;
            a{
                width: 100%;
                img{
                    height: 25vw !important; 
                }
            }
        }
        .footer-other-office{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            a{
                text-align: center;
         
                img{
                    height: 25vw !important; 
                }
            }
        }
     }
     @media only screen and (max-width: 576px) {
        .footer-main-office{
            a{
                img{
                    height: 70vw !important; 
                }
            }
        }
        .footer-other-office{
            grid-template-columns: 1fr;
            a{
                img{
                    height: 70vw !important; 
                }
            }
        }
    }
    @media only screen and (max-width: 300px) {
        .footer-main-office{
            a{
                img{
                    height: 90vw !important; 
                }
            }
        }
        .footer-other-office{
            padding:0;
            a{
                img{
                    height: 90vw !important; 
                }
            }
        }
    }
    .office_social_links{
        display: flex;
        justify-content: center;
        margin:2rem 0;
        a{
            width: auto!important;
            margin:0 .5rem;
            img{
                width: 20px;
                height: 20px!important;
                filter:grayscale(1)invert(.7);
                object-fit: contain;
                padding:0;
            }
        }
        @media only screen and (max-width: 768px) {
            margin-top: 0;
        }
    }
} 

.site-map > ul {
    display:table;
    width:100%;
}
.site-map > ul > li {
    display:table-cell;
    padding:20px 0;
}
.site-map li li {
    display:block;
    margin:10px 0;
}
.site-map a {
    color:inherit;
    text-decoration:none;
    display:block;
    padding-left:30px;
    position:relative;
    line-height:30px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}